import api from "@/api/axios";

const state = {
  accountReturns: [],
  platformReturns: [],
  transfers: [],
  loading: false,
  error: null,
  chartData: {
    daily: {
      labels: [],
      datasets: [],
    },
    cumulative: {
      labels: [],
      datasets: [],
    },
    equity: {
      labels: [],
      datasets: [],
    },
  },
};

const getters = {
  getAccountReturns: (state) => state.accountReturns,
  getPlatformReturns: (state) => state.platformReturns,
  getTransfers: (state) => state.transfers,
  isLoading: (state) => state.loading,
  getError: (state) => state.error,

  // Add new getters for chart data
  getChartData: (state) => (type) => state.chartData[type],
  getAllChartData: (state) => state.chartData,
};

const actions = {
  async fetchAccountData({ commit, dispatch, rootGetters }, accountId) {
    commit("setLoading", true);
    commit("setError", null);

    try {
      // Fetch account daily aggregates
      const accountResponse = await api.get(
        `/api/accounts/${accountId}/daily_aggregate/`
      );
      commit("setAccountReturns", accountResponse.data.results);

      // Process chart data after getting returns
      dispatch("processChartData", accountResponse.data.results);

      // Fetch transfers
      const transfersResponse = await api.get(`/api/transfers/${accountId}`);
      commit("setTransfers", transfersResponse.data.results);

      // If user is admin, fetch platform data
      if (rootGetters["auth/isAdmin"]) {
        const platformResponse = await api.get(
          "/api/platform/daily_aggregate/"
        );
        commit("setPlatformReturns", platformResponse.data.results);
      }
    } catch (error) {
      console.error("Error fetching reporting data:", error);
      commit(
        "setError",
        error.response?.data?.error || "Failed to fetch reporting data"
      );
    } finally {
      commit("setLoading", false);
    }
  },

  // New action to process chart data
  processChartData({ commit }, returns) {
    const chartData = {
      daily: {
        labels: returns.map((r) => r.date),
        datasets: [
          {
            label: "Platform Daily Profit",
            data: returns.map((r) => r.platform_net_realized_pnl || 0),
            borderColor: "#FF6384",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            fill: true,
            tension: 0.4,
          },
        ],
      },
      cumulative: {
        labels: returns.map((r) => r.date),
        datasets: [
          {
            label: "Platform Cumulative Profit",
            data: returns.map((r) => r.platform_cumulative_realized_pnl || 0),
            borderColor: "#4ADE80",
            backgroundColor: "rgba(74, 222, 128, 0.2)",
            fill: true,
            tension: 0.4,
          },
          {
            label: "BTC Price",
            data: returns.map((r) => r.btc_price || 0),
            borderColor: "#F59E0B",
            backgroundColor: "transparent",
            fill: false,
            tension: 0.4,
            yAxisID: "btc-price",
          },
        ],
      },
      equity: {
        labels: returns.map((r) => r.date),
        datasets: [
          {
            label: "Platform Portfolio Value",
            data: returns.map((r) => r.platform_pv_end_of_day || 0),
            borderColor: "#60A5FA",
            backgroundColor: "rgba(96, 165, 250, 0.2)",
            fill: true,
            tension: 0.4,
          },
          {
            label: "Platform Initial Investment",
            data: returns.map((r) => r.platform_initial_investment || 0),
            borderColor: "#F472B6",
            backgroundColor: "rgba(244, 114, 182, 0.2)",
            fill: true,
            tension: 0,
          },
        ],
      },
    };

    // Add secondary y-axis for BTC price in cumulative chart options
    const chartOptions = {
      cumulative: {
        scales: {
          y: {
            type: "linear",
            display: true,
            position: "left",
          },
          "btc-price": {
            type: "linear",
            display: true,
            position: "right",
            grid: {
              drawOnChartArea: false,
            },
          },
        },
      },
    };

    commit("setChartData", { data: chartData, options: chartOptions });
  },

  async fetchPlatformData({ commit }) {
    commit("setLoading", true);
    commit("setError", null);

    try {
      const response = await api.get("/api/platform/daily_aggregate/");
      commit("setPlatformReturns", response.data.results);
    } catch (error) {
      console.error("Error fetching platform data:", error);
      commit(
        "setError",
        error.response?.data?.error || "Failed to fetch platform data"
      );
    } finally {
      commit("setLoading", false);
    }
  },

  clearReportingData({ commit }) {
    commit("setAccountReturns", []);
    commit("setPlatformReturns", []);
    commit("setTransfers", []);
    commit("setError", null);
  },
};

const mutations = {
  setAccountReturns(state, returns) {
    state.accountReturns = returns;
  },
  setPlatformReturns(state, returns) {
    state.platformReturns = returns;
  },
  setTransfers(state, transfers) {
    state.transfers = transfers;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  // Add new mutation for chart data
  setChartData(state, chartData) {
    state.chartData = chartData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
