<template>
  <div class="flex items-center">
    <img
      src="@/assets/quantm-alpha-logo-small.png"
      alt="QuantM Alpha Logo"
      class="h-8 w-8 mr-2"
    >
    <span class="text-xl font-oxygen">QuantM Alpha</span>
  </div>
</template>

<script>
export default {
  name: "BrandComponent",
};
</script>

<style scoped>
@font-face {
  font-family: "Oxygen";
  src: url("@/assets/fonts/Oxygen-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.font-oxygen {
  font-family: "Oxygen", sans-serif;
}
</style>
