import axios from "@/api/axios";

const state = {
  bots: [],
  botsOverview: [],
  loading: false,
  error: null,
};

const getters = {
  allBots: (state) => state.bots,
  botsOverview: (state) => state.botsOverview,
  isLoading: (state) => state.loading,
  error: (state) => state.error,
};

const actions = {
  async fetchBots({ commit }) {
    commit("setLoading", true);
    try {
      const response = await axios.get("/api/bots/");
      commit("setBots", response.data);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchBotsOverview({ commit }) {
    commit("setLoading", true);
    try {
      const response = await axios.get("/api/bots/portal_list/");
      commit("setBotsOverview", response.data);
    } catch (error) {
      commit("setError", error.message);
    } finally {
      commit("setLoading", false);
    }
  },
};

const mutations = {
  setBots: (state, bots) => (state.bots = bots),
  setBotsOverview: (state, botsOverview) => (state.botsOverview = botsOverview),
  setLoading: (state, status) => (state.loading = status),
  setError: (state, error) => (state.error = error),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
