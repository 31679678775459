<!-- src/views/NotFoundView.vue -->
<template>
  <div class="min-h-[calc(100vh-128px)] flex items-center justify-center px-4">
    <div class="text-center">
      <!-- BTC Price Ticker -->
      <div class="mb-6 overflow-hidden relative w-full max-w-3xl mx-auto">
        <div class="ticker-container card">
          <div class="ticker-wrapper">
            <!-- First set -->
            <div class="ticker-content">
              <div
                v-for="(currency, index) in currencies"
                :key="`${currency}-${index}`"
                class="ticker-item"
              >
                <div class="flex items-center space-x-3 px-4 py-1">
                  <span class="font-mono text-muted text-sm">BTC/{{ currency }}</span>
                  <span class="font-mono font-medium text-primary-light">
                    {{ formatPrice(prices[currency], currency) }}
                  </span>
                </div>
              </div>
            </div>
            <!-- Second set -->
            <div class="ticker-content">
              <div
                v-for="(currency, index) in currencies"
                :key="`${currency}-duplicate-${index}`"
                class="ticker-item"
              >
                <div class="flex items-center space-x-3 px-4 py-1">
                  <span class="font-mono text-muted text-sm">BTC/{{ currency }}</span>
                  <span class="font-mono font-medium text-primary-light">
                    {{ formatPrice(prices[currency], currency) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Decorative crypto-themed 404 graphic -->
      <div class="mb-8 relative">
        <div
          class="text-9xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary-light to-secondary-light opacity-20 select-none"
        >
          404
        </div>
      </div>

      <!-- Error message -->
      <h1 class="text-2xl sm:text-3xl font-bold text-muted mb-4">
        Page Not Found
      </h1>
      <p class="text-muted mb-8 max-w-md mx-auto">
        The trading route you're looking for seems to have volatilized. Let's
        get you back to a stable position.
      </p>

      <!-- Action buttons -->
      <div class="flex items-center justify-center space-x-4">
        <button
          class="px-6 py-2 card hover:bg-gray-100 dark:hover:bg-dark-700 transition-colors duration-200 inline-flex items-center flex flex-row"
          @click="$router.go(-1)"
        >
          <svg
            class="w-5 h-5 mr-2 text-muted"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          <span class="text-muted">Go Back</span>
        </button>
        <router-link
          to="/dashboard"
          class="px-6 py-2 bg-primary-light text-white rounded-lg hover:bg-primary transition-colors duration-200 flex items-center"
        >
          Dashboard
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NotFoundView",
  data() {
    return {
      prices: {},
      currencies: [
        "USD",
        "EUR",
        "GBP",
        "JPY",
        "AUD",
        "NZD",
        "SGD",
        "CAD",
        "CHF",
        "CNY",
      ],
      priceInterval: null,
    };
  },
  mounted() {
    this.updatePrices();
    this.priceInterval = setInterval(this.updatePrices, 30000);

    this.$nextTick(() => {
      this.setTickerWidth();
      window.addEventListener("resize", this.setTickerWidth);
    });
  },
  beforeUnmount() {
    if (this.priceInterval) {
      clearInterval(this.priceInterval);
    }
    window.removeEventListener("resize", this.setTickerWidth);
  },
  methods: {
    formatPrice(price, currency) {
      if (!price) return "...";

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: currency === "JPY" ? 0 : 2,
        maximumFractionDigits: currency === "JPY" ? 0 : 2,
      });

      return formatter.format(price);
    },
    async updatePrices() {
      try {
        // Get BTC/USD price from Bybit
        const bybitResponse = await axios.get(
          "https://api.bybit.com/v5/market/tickers",
          {
            params: {
              category: "spot",
              symbol: "BTCUSDT",
            },
          }
        );

        if (
          bybitResponse.data.retCode === 0 &&
          bybitResponse.data.result.list.length > 0
        ) {
          const btcUsdPrice = parseFloat(
            bybitResponse.data.result.list[0].lastPrice
          );
          this.prices.USD = btcUsdPrice;

          // Get exchange rates for other currencies
          const response = await fetch(
            "https://api.exchangerate-api.com/v4/latest/USD"
          );
          const data = await response.json();

          // Calculate BTC price in other currencies
          this.currencies.forEach((currency) => {
            if (currency !== "USD") {
              this.prices[currency] = btcUsdPrice * data.rates[currency];
            }
          });
        }
      } catch (error) {
        console.error("Failed to fetch prices:", error);
      }
    },
    setTickerWidth() {
      const tickerContent = this.$el.querySelector(".ticker-content");
      if (tickerContent) {
        const width = tickerContent.offsetWidth;
        document.documentElement.style.setProperty(
          "--ticker-width",
          `${width}px`
        );
      }
    },
  },
};
</script>

<style scoped>
.ticker-container {
  width: 100%;
  overflow: hidden;
  padding: 4px 0;
  border: 1px solid rgba(128, 128, 128, 0.2);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  mask: linear-gradient(90deg, transparent, #000 5%, #000 95%, transparent);
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    #000 5%,
    #000 95%,
    transparent
  );
}

.ticker-wrapper {
  display: flex;
  animation: tickerMove 30s linear infinite;
}

.ticker-content {
  display: flex;
  flex-shrink: 0;
}

.ticker-item {
  flex-shrink: 0;
  border-right: 1px solid rgba(128, 128, 128, 0.2);
}

@keyframes tickerMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
</style>
