import api from "@/api/axios";

export default {
  namespaced: true,
  state: {
    currentTheme: localStorage.getItem("theme") || "light",
  },
  mutations: {
    setTheme(state, theme) {
      state.currentTheme = theme;
      localStorage.setItem("theme", theme);
      document.documentElement.setAttribute("data-theme", theme);
    },
  },
  actions: {
    async initTheme({ commit, rootState, dispatch }) {
      const localTheme = localStorage.getItem("theme");
      if (localTheme) {
        commit("setTheme", localTheme);
      } else if (rootState.auth.isAuthenticated) {
        try {
          const response = await api.get("/api/auth/user/");
          const userTheme = response.data.color_mode || "light";
          await dispatch("setTheme", userTheme);
        } catch (error) {
          console.error("Failed to fetch user theme:", error);
          commit("setTheme", "light");
        }
      } else {
        commit("setTheme", "light");
      }
    },
    async setTheme({ commit, rootState }, theme) {
      commit("setTheme", theme);
      if (rootState.auth.isAuthenticated) {
        try {
          await api.put("/api/auth/user/", { color_mode: theme });
        } catch (error) {
          console.error("Failed to update theme on the server:", error);
        }
      }
    },
    setThemeWithoutAPI({ commit }, theme) {
      commit("setTheme", theme);
    },
  },
  getters: {
    currentTheme: (state) => state.currentTheme,
  },
};
