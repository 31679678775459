import api from "@/api/axios";
import router from "@/router";

const state = {
  token: localStorage.getItem("token") || null,
  user: null,
  authChecked: false,
  isAdmin: false,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  authChecked: (state) => state.authChecked,
  isAdmin: (state) => state.isAdmin,
};

const actions = {
  async login({ commit, dispatch }, credentials) {
    try {
      const response = await api.post("/api/auth/login/", credentials);
      if (response.data.require_2fa) {
        return response.data;
      } else {
        const token = response.data.token;
        localStorage.setItem("token", token);
        commit("setToken", token);

        // Fetch user data immediately after login
        await dispatch("fetchUserData");

        return { require_2fa: false };
      }
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  },
  async verifyTOTP({ commit }, { tempToken, totpCode }) {
    try {
      const response = await api.post("/api/totp/", {
        temp_token: tempToken,
        token: totpCode,
      });
      if (response.data.success) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        commit("setToken", token);
        commit("setUser", response.data);
        return { success: true };
      } else {
        throw new Error(response.data.error || "Verification failed");
      }
    } catch (error) {
      console.error("TOTP verification failed:", error);
      throw error;
    }
  },
  async logout({ commit, dispatch }) {
    // Immediately set isAuthenticated to false to hide the navbar
    commit("setToken", null);
    commit("setUser", null);
    commit("setAuthChecked", true);
    commit("setIsAdmin", false);

    // Then perform cleanup operations
    const currentTheme = localStorage.getItem("theme") || "light";
    localStorage.removeItem("token");

    // Preserve the current theme
    await dispatch("theme/setThemeWithoutAPI", currentTheme, { root: true });

    // Use router.push with replace: true instead of router.replace
    router.push({ path: "/login" }, () => {}, { replace: true });
  },
  async checkAuth({ commit, state }) {
    if (state.authChecked) return state.token !== null;

    const token = localStorage.getItem("token");

    if (!token) {
      commit("setAuthChecked", true);
      return false;
    }

    try {
      const response = await api.get("/api/auth/user/");
      commit("setToken", token);
      commit("setUser", response.data);
      commit("setAuthChecked", true);
      commit("setIsAdmin", response.data.is_admin); // Use is_admin here
      return true;
    } catch (error) {
      console.error("Token validation failed:", error);
      if (error.response && error.response.status === 500) {
        console.error("Server error occurred. Please try again later.");
      }
      commit("setToken", null);
      commit("setUser", null);
      commit("setIsAdmin", false);
      localStorage.removeItem("token");
      commit("setAuthChecked", true);
      return false;
    }
  },
  async updateToken({ commit }, token) {
    localStorage.setItem("token", token);
    commit("setToken", token);
  },
  setToken({ commit }, token) {
    commit("setToken", token);
    localStorage.setItem("token", token);
  },
  setUser({ commit }, user) {
    commit("setUser", user);
  },
  async fetchUserData({ commit, dispatch }) {
    try {
      const response = await api.get("/api/auth/user/");
      commit("setUser", response.data);
      commit("setIsAdmin", response.data.is_admin);
      // Update theme based on user preference
      dispatch("theme/setTheme", response.data.color_mode || "light", {
        root: true,
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      throw error;
    }
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    localStorage.setItem("token", token);
  },
  setUser(state, user) {
    state.user = user;
    state.isAdmin = user ? user.is_admin : false;
  },
  setAuthChecked(state, checked) {
    state.authChecked = checked;
  },
  setIsAdmin(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
