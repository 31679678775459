import axios from "axios";

const API_URL = "https://api.bybit.com/v5/market";

export default {
  namespaced: true,

  state: {
    btcPrice: null,
    lastFetchTime: 0,
    CACHE_DURATION: 30000, // 30 seconds
    pollInterval: null,
  },

  getters: {
    currentBTCPrice: (state) => state.btcPrice,
    isPriceFresh: (state) =>
      Date.now() - state.lastFetchTime < state.CACHE_DURATION,
  },

  mutations: {
    SET_BTC_PRICE(state, price) {
      state.btcPrice = price;
      state.lastFetchTime = Date.now();
    },
    SET_POLL_INTERVAL(state, interval) {
      state.pollInterval = interval;
    },
  },

  actions: {
    async initializePrice({ dispatch }) {
      await dispatch("fetchBTCPrice");
      dispatch("startPolling");
    },

    async fetchBTCPrice({ commit, state, getters }) {
      if (state.btcPrice && getters.isPriceFresh) {
        return state.btcPrice;
      }

      try {
        const response = await axios.get(`${API_URL}/tickers`, {
          params: {
            category: "spot",
            symbol: "BTCUSDT",
          },
        });

        if (
          response.data.retCode === 0 &&
          response.data.result.list.length > 0
        ) {
          const price = parseFloat(response.data.result.list[0].lastPrice);
          commit("SET_BTC_PRICE", price);
          return price;
        }

        throw new Error("Invalid response from Bybit API");
      } catch (error) {
        console.error("Error fetching BTC price from Bybit:", error);
        return state.btcPrice || null;
      }
    },

    startPolling({ commit, dispatch, state }) {
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
      }

      const interval = setInterval(() => {
        dispatch("fetchBTCPrice");
      }, state.CACHE_DURATION);

      commit("SET_POLL_INTERVAL", interval);
    },

    cleanup({ state }) {
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
      }
    },
  },
};
